.profilePage {
	width: 980px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(0, 0, 0, 0.7);
}

.pp {
	margin-bottom: 30px !important;
	height: 50px;
}

.dataContainer {
	width: 960px;
	height: 218px;
	margin-bottom: 111px;
}

.dataAvatarWrapper {
	display: flex;
	justify-content: space-between;
	height: 185px;
}

.dataFlex {
	display: flex;
	justify-content: space-between;
}

.data {
	width: auto;
	height: 185px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.data-item {
	width: max-content;
}

.data-item-input {
	display: flex;
	justify-content: space-between;
}

#profileForm .ant-form-item-control-input-content {
	display: flex;
	width: max-content;
	justify-content: space-between;
	align-items: center;
}

#profileForm .ant-form {
	display: flex;
	flex-direction: column;
	width: 300px;
	height: 185px;
}

.avatarChangeBtn {
	width: 180px;
	height: 37px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.avatar-uploader > .ant-upload {
	width: 250px;
	height: 185px;
}

.ant-upload-picture-card-wrapper {
	width: auto;
}