.ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0px !important;
    margin-right: 8px !important;
}

.ant-page-header {
    width: inherit;
    background-color: unset;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    width: 980px;
    z-index: 1000;
}

.logoLoginWrapper {
    display: flex;
    width: 190px;
    justify-content: space-between;
}

.logoLoginWrapperSearch {
    display: flex;
    min-width: 464px;
    height: 50px;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    width: 140px;
    height: 50px;
    margin-right: 20px;
}

.logoSearch {
    width: 140px;
    height: 50px;
    margin-left: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.login {
    min-width: 80px;
    height: 50px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.7);
    margin-right: 20px;
}

.logout {
    min-width: 80px;
    height: 50px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.05em;
    margin-right: 20px;
}

.headerBtn {
    min-width: 80px;
    height: 50px;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    border: none;
}

.logoutSearchWrapper {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search {
    position: sticky;
    top: 0px;
    backdrop-filter: blur(12px);
    width: 100vw;
    display: flex;
    justify-content: space-between;
}

.authName {
    min-width: max-content;
    height: 50px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}

.authNameSearch {
    min-width: 304px;
}


.salesLogoutWrapper{
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 31px;
}

.mySales {
    min-width: 142px;
    height: 50px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.05em;
    margin-right: 20px;
}

.logged {
    justify-content: flex-end;
}