.searchPage {
    position: absolute;
    top: 90px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 90px);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.list {
    width: 40%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.list::-webkit-scrollbar {
    background-color: rgba(121, 146, 192, 0.1);
}

.list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
}