.mainTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 110px;
    margin-top: 52px;
    margin-bottom: 63px;
}

.mainText{
    width: 980px;
    height: 50px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 8px;
}

.mainTextSmall {
    width: 940px;
    height: 50px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
}