@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap");

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden !important;
}

body::-webkit-scrollbar {
    background-color: rgba(121, 146, 192, 0.1);
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
}

.ant-carousel {
	height: 100%;
}

.slick-slider {
	height: inherit;
}

.slick-list {
	height: inherit;
}

.slick-track {
	height: inherit;
}

.slick-slide {
	height: inherit;
}

.slick-slide > div {
	height: inherit;
}

.slick-slide > div > img {
	height: inherit;
	object-fit: contain;
}

.slick-dots-bottom > li > button {
	background-color: rgb(66, 132, 255) !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

input {
	font: 300 14px "Roboto";
}

.fw300 {
	font-family: Roboto !important;
	font-style: normal !important;
	font-weight: 300 !important;
	font-size: 15px !important;
	line-height: 20px !important;
	letter-spacing: 0.05em !important;
}

.fw500 {
	font-family: Roboto !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 16px !important;
	line-height: 24px !important;
}

.big {
	font-size: 48px !important;
	line-height: 46px !important;
}

.fs14 {
	font-size: 14px !important;
	line-height: 22px !important;
	letter-spacing: 0px !important;
}

.fs15 {
	font-size: 15px !important;
	line-height: 28px !important;
}

.fs16 {
	font-size: 16px !important;
	line-height: 30px !important;
}

.fs24 {
	font-size: 24px !important;
	line-height: 28px !important;
}

.fs28 {
	font-size: 20px !important;
    line-height: 28px !important;
}

.lh28 {
	line-height: 28px !important;
}

.lh32 {
	line-height: 32px !important;
}

.up {
	text-transform: uppercase !important;
}

.ls {
	letter-spacing: 0.05em !important;
}

.fade-enter {
	opacity: 0;
	animation-duration: .5s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
	animation-play-state: paused;
}

.fade-appear {
	opacity: 0;
	animation-duration: .5s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
	animation-play-state: paused;
}

.fade-leave {
	animation-duration: .5s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
	animation-play-state: paused;
}

.fade-enter.fade-enter-active {
	animation-name: fadeIn;
	animation-play-state: running;
}

.fade-appear.fade-appear-active {
	animation-name: fadeIn;
	animation-play-state: running;
}

.fade-leave.fade-leave-active {
	animation-name: fadeOut;
	animation-play-state: running;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}