.background {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(6px);
	z-index: 2;
}

.loginForm {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 420px;
	height: 389px;
	background: #ffffff;
	border-radius: 28px;
	z-index: 3;
}

.registration {
	height: 490px;
}

.loginFormRel {
	position: relative;
	width: 100%;
	height: 100%;
}

.loginForm_title {
	position: absolute;
	width: 180px;
	height: 32px;
	left: 120px;
	top: 39px;
	text-align: center;
	color: #000000;
	margin-bottom: 9px;
	cursor: default;
}

.loginForm_title__wrap {
	position: absolute;
	width: 220px;
	height: 20px;
	top: 80px;
	left: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;
}

.reg {
	color: #00b0ff;
	cursor: pointer;
	margin-left: 5px;
}

.reg:hover {
	color: #008dd9;
}

.log {
	color: #00b0ff;
	cursor: pointer;
	margin-left: 5px;
}

.log:hover {
	color: #008dd9;
}

.lfAfterTitileText {
	text-align: center;
}

.loginForm_input {
	position: absolute;
	width: 300px;
	height: 40px;
}

.loginForm_inputWrap {
	position: absolute;
	width: 300px;
	height: 100px;
	top: 155px;
	left: 60px;
}

.inputWrapReg {
	height: 220px;
}

.fio {
	top: 0px;
}

.phone {
	top: 60px;
}

.email {
	top: 0px;
}

.emailReg {
	top: 120px;
}

.password {
	top: 60px;
}

.passwordReg {
	top: 180px;
}

.mb20 {
	margin-bottom: 20px;
}

.loginForm_forgotPass {
	position: absolute;
	width: 116px;
	height: 22px;
	left: 245px;
	bottom: 113px;
	text-align: right;
	color: #00b0ff;
	cursor: pointer;
}

.loginForm_forgotPass:hover {
	color: #008dd9;
}

.loginFormBtn {
	position: absolute;
	width: 180px;
	height: 40px;
	left: 120px;
	bottom: 39px;
	border-radius: 8px;
	text-align: center;
	color: #ffffff;
}
